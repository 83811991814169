<template>
  <div class="guide-card">
    <div class="guide-card__wrapper">
      <Column>
        <div class="guide-card__image-holder">
          <img :src="params && params.images[0]"
               class="guide-card__image"
               alt=""/>
          <div class="guide-card__image-holder-after"
               v-on:click="openGuide"></div>
          <Row v-if="params && params.socials"
               class="guide-card__social-networks">
            <div v-for="(social, index) in params.socials"
                 :key="index"
                 class="guide-card__social-network">
              <a class="guide-card__social-network-link"
                 :href="social">
                <img :src="require(`@/assets/images/svg/${index}-icon.svg`)"/>
              </a>
            </div>
          </Row>
        </div>
        <div class="guide-card__info-holder"
             v-on:click="openGuide">
          <p class="guide-card__info-name">{{params && params.name}}</p>
          <p class="guide-card__info-description">{{params && params.annotation}}</p>
        </div>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuideCard',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatDate: 'DD MMMM, HH:mm',
    };
  },
  methods: {
    openGuide() {
      this.$store.commit('OPEN_POPUP', {
        clear: true,
        width: this.$viewport.desktop ? 800 : '100%',
        height: '100vh',
        horizontal: 'right',
        vertical: 'center',
        type: 'PopupGuide',
        additions: this.params,
      });
    },
  },
};
</script>
